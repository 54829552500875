<template>
  <div class="w-full flex flex-wrap gap-4 mb-4">
    <label
      v-for="item in sortedOptions"
      :key="item.scaleId"
      class="flex items-center cursor-pointer"
      @click="toggleItem(item)"
    >
      <input type="checkbox" v-model="item.checked" class="hidden" />
      <div
        class="customCheckbox w-4 h-4 border rounded-sm flex items-center justify-center cursor-pointer"
        :style="{
          backgroundColor: item.checked ? item.color : '#fff',
          borderColor: item.color,
        }"
      ></div>

      <div class="ml-2 text-xs text-[#5A5A5F]">
        {{ item.title }}
        <span v-if="item.checked">: {{ getCurrentValue(item.scaleId) }}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "LegendComponent",
  inject: ["legendWithData", "setLegendData"],
  props: {
    options: Array,
  },

  computed: {
    sortedOptions() {
      return [...this.options].sort((a, b) => b.checked - a.checked);
    },
  },

  methods: {
    toggleItem(item) {
      const index = this.legendWithData.value.findIndex(
        (el) => el.scaleId === item.scaleId
      );

      if (index !== -1) {
        this.legendWithData.value[index].checked =
          !this.legendWithData.value[index].checked;

        this.setLegendData([...this.legendWithData.value]);
      }
    },

    getCurrentValue(scaleId) {
      const item = this.legendWithData.value.find((i) => i.scaleId === scaleId);
      return item ? item.currentValue : "-";
    },
  },
};
</script>

<style scoped>
.customCheckbox {
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
</style>
