<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <div
      class="w-full min-h-[60px] flex flex-wrap justify-center items-center relative"
    >
      <BackButtonComponent />
    </div>

    <div class="flex flex-wrap gap-4 w-full mb-4">
      <TableComponent
        v-if="reports && reports.TRACKING"
        class="w-full max-w-[320px]"
        :tableData="tableData1"
      />
      <TableComponent
        v-if="reports && reports.TRACKING"
        class="w-full max-w-[420px]"
        :tableData="tableData2"
      />
      <TableComponent
        v-if="reports && reports.TRACKING"
        class="w-full max-w-[320px]"
        :tableData="tableData3"
      />
    </div>

    <div
      v-if="reports && reports.TRACKING"
      class="w-full mx-auto mb-4 px-8 py-4 pb-4 flex flex-col justify-between bg-white shadow rounded-lg relative"
      :style="{ paddingBottom: `${bgHeightValue}px` }"
    >
      <div class="flex items-center mb-4">
        <div class="text-[#5A5A5F] text-[21px] font-semibold text-center mr-4">
          {{ $t("tracking.Tracking") }}
        </div>
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="trackingMode"
          :data="trackingMode"
          :options="trackingModesOptions"
          @update="handleTrackingMode"
        />

        <template
          v-if="
            currentTabIdx === 0 && tabs[currentTabIdx].isEnabled && +jumpCount
          "
        >
          <div
            class="text-[#5A5A5F] text-[21px] font-semibold text-center mx-4"
          >
            {{ $t("tracking.Jumps") }}
          </div>
          <SelectControl
            :style="{
              flexDirection: 'row',
              marginBottom: '0',
              width: 'auto',
            }"
            dataKey="jumps"
            :options="jumpOptions.map((option) => option.label)"
            :data="selectedJump?.label || null"
            defaultOption="Select jump"
            @update="selectNewJump"
          />

          <button
            v-if="!isAnimationModalOpen"
            :disabled="!selectedJump"
            class="text-[12px] w-auto rounded p-2 bg-[#9A8053] text-white ml-2"
            :class="{ 'bg-gray-400': !selectedJump }"
            @click="openAnimationModal"
          >
            {{ $t("tracking.Open animation") }}
          </button>
        </template>
        <InformationCircleIcon
          class="w-6 h-6 ml-auto hover:text-[gray] cursor-pointer"
          @click="openShortcutModal"
        />
      </div>
      <div class="w-full flex flex-wrap">
        <div v-if="reports && reports.TRACKING" class="w-full mb-4">
          <div class="block">
            <div class="block">
              <nav
                class="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-x-auto"
                aria-label="Tabs"
              >
                <button
                  v-for="(tab, tabIdx) in tabs"
                  :key="tab.name"
                  :class="[
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tab.isEnabled ? '' : 'hidden',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative flex-1 whitespace-nowrap bg-[#FAF0E6] py-2 px-4 text-center hover:bg-gray-300 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold flex items-center justify-center',
                  ]"
                  :aria-current="tab.current ? 'page' : undefined"
                  @click="handleSwitchTab(tabIdx)"
                >
                  <span>{{ $t(tab.name) }}</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      tab.current ? 'bg-[#9A8053]' : 'bg-indigo-200',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    ]"
                  />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-wrap justify-between border-2 p-4">
        <HorseTrackingComponent
          :tracking="
            cutValue
              ? currentMappedData.slice(cutValue[0], cutValue[1])
              : currentMappedData
          "
          :lengthPreviousValues="historyLength"
          :fromToCutValue="fromToCutValue"
          :selectedGaits="selectedGaits"
          :trackingMarkersLength="trackingMarkersLength"
          :cutValue="cutValue"
          :currentTabIdx="currentTabIdx"
        />

        <div
          class="flex flex-col items-start w-full lg:max-w-[52%]"
          :class="{
            'lg:max-w-none': isFullscreenMapOpen,
          }"
        >
          <MultichartWrapper
            v-if="
              reports &&
              currentTabIdx === 0 &&
              tabs[currentTabIdx].isEnabled &&
              !isFullscreenMultichartOpen
            "
            :mappedData="currentMappedData"
            :selectedGaits="selectedGaits"
            :cutValue="cutValue"
            :secondsByGait="secondsByGait"
            :trackingMarkersLength="trackingMarkersLength"
            :multicharts="multicharts"
          />

          <EnergyChartComponent
            v-if="currentTabIdx === 1 && tabs[currentTabIdx].isEnabled"
            :datasets="
              reports.EXPENDITURE_ENERGY_METRICS.raw
                ? reports.EXPENDITURE_ENERGY_METRICS.raw
                : reports.EXPENDITURE_ENERGY_METRICS
            "
            :indexColor="indexColor"
            :cutValue="cutValue"
          />
          <EnergyByAllurChartComponent
            v-if="currentTabIdx === 2 && tabs[currentTabIdx].isEnabled"
            :datasets="
              reports.EXPENDITURE_ENERGY_METRICS.raw
                ? reports.EXPENDITURE_ENERGY_METRICS.raw
                : reports.EXPENDITURE_ENERGY_METRICS
            "
            :indexColor="indexColor"
            :cutValue="cutValue"
          />
          <CenterOfGravityChartComponent
            v-if="currentTabIdx === 3 && tabs[currentTabIdx].isEnabled"
            :tracking="
              cutValue
                ? currentMappedData.slice(cutValue[0], cutValue[1])
                : currentMappedData
            "
            :maxRadius="maxRadius"
            :lengthPreviousValues="historyLength"
          />
        </div>
      </div>

      <div class="mt-4">
        <div
          v-if="!isFullscreenNavigationOpen"
          class="relative w-full flex justify-between items-center gap-8 bg-gray-50 border-2 rounded-md p-4"
        >
          <button
            @click.prevent="openFullscreenNavigation"
            class="absolute top-2 right-2 z-10 flex items-center"
          >
            <ArrowsPointingOutIcon class="h-6 w-6" aria-hidden="true" />
          </button>

          <div class="w-[70%]">
            <div class="mb-4 mx-8">
              <PlayControllerSliderComponent
                :trackingMarkersLength="trackingMarkersLength"
                :cutValue="cutValue"
              />
            </div>
            <div class="mt-20 mb-4 mx-8">
              <CutTrainingComponent
                :trackingMarkersLength="reports.TRACKING.x.length"
                @cutTrackingValue="cutTrackingValue"
                @changeCutValue="changeCutValue"
              />
            </div>
          </div>

          <div class="w-[30%]">
            <PlayControllerButtonsComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />

            <div class="w-full">
              <div class="w-200px flex flex-col mt-2 ml-2">
                <span class="text-[#5A5A5F] text-[18px] font-semibold"
                  >{{ $t("tracking.Horse gait history") }} =
                  {{ (historyLength / 5).toFixed(0) }}
                  {{ $t("tracking.seconds") }}</span
                >
                <Slider
                  class="w-[250px] h-1 mt-2 mb-4 cursor-pointer"
                  :tooltips="false"
                  v-model="historyLength"
                  :max="1500"
                  :min="50"
                />
              </div>
              <div class="flex flex-wrap mt-1">
                <div
                  v-for="item of movements"
                  :key="item.id"
                  class="flex items-center mx-1"
                >
                  <input
                    type="checkbox"
                    class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                    :style="{ color: item.backgroundColor }"
                    :id="item.title"
                    v-model="item.checked"
                  />
                  <label
                    :for="item.title"
                    class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
                  >
                    <span>{{ $t(item.title) }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex mt-4">
              <button
                class="p-2 rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053]"
                @click="cutTrackingValue"
              >
                {{ $t("tracking.Crop") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="reports?.TREND && !reports?.TRACKING"
      class="mt-64 text-[#5A5A5F] text-[21px] font-semibold text-center"
    >
      {{ $t("tracking.No data available for this workout") }}
    </div>
  </div>

  <div class="self-start">
    <label class="mr-4" for="bgHeightValue">
      Additional height for tracking page
    </label>
    <input
      id="bgHeightValue"
      class="border-2 border-gray-400 rounded-lg overflow-hidden"
      type="number"
      :min="20"
      :max="1000"
      :step="20"
      placeholder="Container height"
      v-model="bgHeightValue"
      @input="validateInput"
    />
    <p v-if="errorMessage" class="text-red-500 mt-2">{{ errorMessage }}</p>
  </div>

  <vue-draggable-resizable
    v-if="isFullscreenMultichartOpen"
    :key="multichartDragKey"
    ref="multichart"
    :active="true"
    @dragStop="(x, y) => saveCoordinates(x, y, 'multichart')"
    @resizeStop="
      (left, top, width, height) =>
        saveSize(left, top, width, height, 'multichart')
    "
    :style="{ zIndex: mainLayer === 'multichart' ? 53 : 52 }"
    :x="separateWindowPositions.multichart.x"
    :y="separateWindowPositions.multichart.y"
    :w="separateWindowPositions.multichart.width"
    :h="separateWindowPositions.multichart.height"
    :drag-handle="'.modal-header'"
    :drag-cancel="'.drag-cancel'"
    :resizable="true"
    :minWidth="600"
    :minHeight="620"
    :handles="['tr', 'mr', 'br', 'bl', 'ml']"
    @activated="handleActivated('multichart')"
    @close="closeChartModal"
  >
    <div class="modal h-full">
      <div
        class="modal-header bg-gray-800 text-white p-2 cursor-move flex justify-between items-center"
      >
        <div>
          <span>Multichart</span>
          <button
            @click="resetPosition('multichart')"
            class="drag-cancel ml-4 p-2 cursor-pointer bg-white text-gray-800 rounded-md hover:bg-gray-100"
          >
            Reset position
          </button>
        </div>
        <button
          class="drag-cancel text-red-500 hover:text-red-700 font-bold"
          @click="closeChartModal"
        >
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="modal-content h-full-available p-4 pb-[280px]">
        <MultichartWrapper
          v-if="reports"
          :mappedData="currentMappedData"
          :selectedGaits="selectedGaits"
          :cutValue="cutValue"
          :secondsByGait="secondsByGait"
          :trackingMarkersLength="trackingMarkersLength"
          :multicharts="multicharts"
        />
      </div>
    </div>
  </vue-draggable-resizable>

  <vue-draggable-resizable
    v-if="isFullscreenNavigationOpen"
    :key="navigationDragKey"
    ref="navigation"
    @dragStop="(x, y) => saveCoordinates(x, y, 'navigation')"
    @resizeStop="
      (left, top, width, height) =>
        saveSize(left, top, width, height, 'navigation')
    "
    :active="true"
    :style="{ zIndex: mainLayer === 'navigation' ? 53 : 52 }"
    :x="separateWindowPositions.navigation.x"
    :y="separateWindowPositions.navigation.y"
    :w="separateWindowPositions.navigation.width"
    :h="separateWindowPositions.navigation.height"
    :drag-handle="'.modal-header'"
    :drag-cancel="'.drag-cancel'"
    :resizable="true"
    :minWidth="600"
    :minHeight="350"
    :handles="['tr', 'mr', 'br', 'bl', 'ml']"
    @activated="handleActivated('navigation')"
    @close="closeNavigationWindow"
  >
    <div class="modal h-full">
      <div
        class="modal-header bg-gray-800 text-white p-2 cursor-move flex justify-between items-center"
      >
        <div>
          <span>Navigation</span>
          <button
            @click="resetPosition('navigation')"
            class="drag-cancel ml-4 p-2 cursor-pointer bg-white text-gray-800 rounded-md hover:bg-gray-100"
          >
            Reset position
          </button>
        </div>
        <button
          class="drag-cancel text-red-500 hover:text-red-700 font-bold"
          @click="closeNavigationWindow"
        >
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="modal-content relative h-full-available">
        <div
          class="relative w-full flex justify-between items-center gap-8 bg-gray-50 border-2 rounded-md p-4"
        >
          <div class="w-[70%]">
            <div class="mb-4 mx-8">
              <PlayControllerSliderComponent
                :trackingMarkersLength="trackingMarkersLength"
                :cutValue="cutValue"
              />
            </div>
            <div class="mt-20 mb-4 mx-8">
              <CutTrainingComponent
                :trackingMarkersLength="reports.TRACKING.x.length"
                @cutTrackingValue="cutTrackingValue"
                @changeCutValue="changeCutValue"
              />
            </div>
          </div>

          <div class="w-[30%]">
            <PlayControllerButtonsComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />

            <div class="w-full">
              <div class="w-200px flex flex-col mt-2 ml-2">
                <span class="text-[#5A5A5F] text-[18px] font-semibold"
                  >{{ $t("tracking.Horse gait history") }} =
                  {{ (historyLength / 5).toFixed(0) }}
                  {{ $t("tracking.seconds") }}</span
                >
                <Slider
                  class="w-[250px] h-1 mt-2 mb-4 cursor-pointer"
                  :tooltips="false"
                  v-model="historyLength"
                  :max="1500"
                  :min="50"
                />
              </div>
              <div class="flex flex-wrap mt-1">
                <div
                  v-for="item of movements"
                  :key="item.id"
                  class="flex items-center mx-1"
                >
                  <input
                    type="checkbox"
                    class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                    :style="{ color: item.backgroundColor }"
                    :id="item.title"
                    v-model="item.checked"
                  />
                  <label
                    :for="item.title"
                    class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
                  >
                    <span>{{ $t(item.title) }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex mt-4">
              <button
                class="p-2 rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053]"
                @click="cutTrackingValue"
              >
                {{ $t("tracking.Crop") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>

  <vue-draggable-resizable
    v-if="isAnimationModalOpen"
    :key="animationDragKey"
    ref="animation"
    :active="true"
    @dragStop="(x, y) => saveCoordinates(x, y, 'animation')"
    @resizeStop="
      (left, top, width, height) =>
        saveSize(left, top, width, height, 'animation')
    "
    :style="{ zIndex: mainLayer === 'animation' ? 53 : 52 }"
    :x="separateWindowPositions.animation.x"
    :y="separateWindowPositions.animation.y"
    :w="separateWindowPositions.animation.width"
    :h="separateWindowPositions.animation.height"
    :drag-handle="'.modal-header'"
    :drag-cancel="'.drag-cancel'"
    :resizable="true"
    :minWidth="600"
    :minHeight="750"
    :handles="['tr', 'mr', 'br', 'bl', 'ml']"
    @activated="handleActivated('animation')"
    @close="closeAnimationModal"
  >
    <div class="modal h-full">
      <div
        class="modal-header bg-gray-800 text-white p-2 cursor-move flex justify-between items-center"
      >
        <div>
          <span>Animation</span>
          <button
            @click="resetPosition('animation')"
            class="drag-cancel ml-4 p-2 cursor-pointer bg-white text-gray-800 rounded-md hover:bg-gray-100"
          >
            Reset position
          </button>
        </div>
        <button
          class="drag-cancel text-red-500 hover:text-red-700 font-bold"
          @click="closeAnimationModal"
        >
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="modal-content h-full-available p-4 pb-[280px]">
        <TrainingSimulator
          :selectedJump="selectedJump"
          :legendWithData="legendWithData"
          :cutValue="cutValue"
          :jumpOptions="jumpOptions"
          :multicharts="multicharts"
          @selectNewJump="selectNewJump"
          class="flex-grow"
        />
      </div>
    </div>
  </vue-draggable-resizable>

  <ModalComponent
    medium
    confirmHidden
    xMark
    :is-open="isShortcutModalOpen"
    @handleCancel="isShortcutModalOpen = false"
  >
    <template v-slot:title>
      <span class="px-2">{{ $t("shortcuts.title") }}</span>
    </template>
    <template v-slot:content>
      <div
        v-for="item in keyboardShortcutsInfo"
        :key="item.id"
        class="text-[#5A5A5F] px-2"
      >
        <span class="font-bold">{{ $t(item.key) }}</span> -
        {{ $t(item.description) }}
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import BackButtonComponent from "@/components/BackButtonComponent.vue";
import Slider from "@vueform/slider";
import { mapActions, mapGetters, mapMutations } from "vuex";
import EnergyChartComponent from "@/components/training/tracking/trackingComponents/EnergyChartComponent.vue";
import CutTrainingComponent from "@/components/training/tracking/CutTrainingComponent.vue";
import HorseTrackingComponent from "@/components/training/tracking/HorseTrackingComponent.vue";
import CenterOfGravityChartComponent from "@/components/training/tracking/centerOfGravityTracking/CenterOfGravityChartComponent.vue";
import EnergyByAllurChartComponent from "@/components/training/tracking/trackingComponents/EnergyByAllurChartComponent.vue";
import TableComponent from "@/components/UI/TableComponent.vue";
import MultichartWrapper from "@/components/training/tracking/trackingComponents/multiChart/MultichartWrapper.vue";
import PlayControllerButtonsComponent from "@/components/training/tracking/PlayControllerButtonsComponent.vue";
import {
  ArrowsPointingOutIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import VueDraggableResizable from "vue-draggable-resizable";

import {
  formatTimestamp,
  getClass,
  getSecond,
} from "@/components/training/helpers";
import {
  defaultSeparateWindowsPosition,
  keyboardShortcutsInfo,
  movements,
  trackingModeKeys,
  trackingModes,
} from "@/components/training/constants";
import SelectControl from "@/components/UI/SelectControl.vue";
import routeNames from "@/router/routes.js";
import ModalComponent from "@/components/ModalComponent.vue";
import {
  formatTimestampToSingleDecimal,
  getTrackIndexFromTimestamp,
} from "@/components/training/tracking/trackingComponents/multiChart/multiChartUtils";
import { computed } from "vue";
import TrainingSimulator from "@/components/training/tracking/trackingComponents/TrainingSimulator.vue";

export default {
  name: "TrackingMainComponent",
  components: {
    TrainingSimulator,
    ArrowsPointingOutIcon,
    XMarkIcon,
    ModalComponent,
    SelectControl,
    PlayControllerButtonsComponent,
    MultichartWrapper,
    TableComponent,
    EnergyByAllurChartComponent,
    CenterOfGravityChartComponent,
    HorseTrackingComponent,
    BackButtonComponent,
    PlayControllerSliderComponent,
    Slider,
    EnergyChartComponent,
    CutTrainingComponent,
    InformationCircleIcon,
    VueDraggableResizable,
  },

  data() {
    return {
      movements,
      trackingModes,
      routeNames,
      keyboardShortcutsInfo,
      currentTabIdx: 0,
      historyLength: 50,
      cutValue: null,
      fromToCutValue: [],
      isShortcutModalOpen: false,
      selectedJump: null,
      legendWithData: [],
      multicharts: null,

      bgHeightValue: 20,
      errorMessage: "",

      navigationDragKey: 0,
      multichartDragKey: 0,
      animationDragKey: 0,
    };
  },

  provide() {
    return {
      legendWithData: computed(() => this.legendWithData),
      setLegendData: this.setLegendData,
      cutValue: computed(() => this.cutValue),
      selectNewJump: this.selectNewJump,
      jumpOptions: computed(() => this.jumpOptions),
    };
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "horseTrackingCard",
      "reports",
      "loading",
      "GPSDatafiles",
      "HRDatafiles",
      "selectedChartsList",
      "isTrackStarted",
      "isTrackPaused",
      "trackingMarkersLength",
      "trackingMode",
      "isAnimationModalOpen",
      "isFullscreenMultichartOpen",
      "isFullscreenMapOpen",
      "isFullscreenNavigationOpen",
      "mainLayer",
      "separateWindowPositions",
    ]),
    jumpOptions() {
      if (this.reports?.JUMP?.length) {
        const allSelects = this.reports?.JUMP.map((jump, index) => ({
          id: index + 1,
          jump,
          start: this.getSecond(jump.reder_start),
          end: this.getSecond(jump.reder_end),
          label: `Jump ${index + 1} at ${this.formatTimestamp(jump.start)}`,
        }));

        if (this.cutValue) {
          const startSliceSecond = Math.round(this.cutValue[0] / 5);
          const endSliceSecond = Math.round(this.cutValue[1] / 5);
          return allSelects.filter((i) => {
            if (i.start > startSliceSecond && i.end < endSliceSecond) {
              return i;
            }
          });
        }

        return allSelects;
      }
      return [];
    },
    trackingModesOptions() {
      return this.GPSDatafiles?.length
        ? this.trackingModes
        : [trackingModeKeys.ML];
    },
    tabs() {
      return [
        {
          name: "tracking.Multichart",
          href: "#",
          current: this.currentTabIdx === 0,
          isEnabled: this.selectedChartsList.includes("Multichart"),
        },
        {
          name: "tracking.Cumulative energy",
          href: "#",
          current: this.currentTabIdx === 1,
          isEnabled: this.selectedChartsList.includes("Cumulative energy"),
        },
        {
          name: "tracking.Expenditure energy",
          href: "#",
          current: this.currentTabIdx === 2,
          isEnabled: this.selectedChartsList.includes("Expenditure energy"),
        },
        {
          name: "tracking.Center of gravity",
          href: "#",
          current: this.currentTabIdx === 3,
          isEnabled: this.selectedChartsList.includes("Center of gravity"),
        },
      ];
    },
    tableData1() {
      return [
        {
          name: this.$t("tracking.Segment distance"),
          value: this.segmentDistance,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment time"),
          value: this.segmentTime,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment energy"),
          value: this.segmentEnergy,
          disabled: false,
        },
      ];
    },
    tableData2() {
      return [
        {
          name: this.$t("tracking.Segment average speed"),
          value: this.segmentAvgSpeed,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment Max speed"),
          value: this.segmentMaxSpeed,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment Max acceleration"),
          value: this.segmentMaxAcceleration,
          disabled: false,
        },
      ];
    },
    tableData3() {
      return [
        {
          name: this.$t("tracking.Segment Average HR"),
          value: this.segmentAvgHeartRate,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("tracking.Segment Max HR"),
          value: this.segmentMaxHeartRate,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("tracking.Jump count"),
          value: this.jumpCount,
          disabled: false,
        },
      ];
    },
    mappedMLData() {
      if (!this.reports || !this.reports.TRACKING?.x) return [];
      return this.reports.TRACKING.x.map((i, index) => {
        return {
          x: this.reports.TRACKING.x[index],
          y: this.reports.TRACKING.y[index],
          phi: this.reports.TRACKING.phi
            ? this.convertToDeg(this.reports.TRACKING.phi[index])
            : 0,
          radius: this.reports.TRACKING.radius
            ? this.reports.TRACKING.radius[index]
            : 0,
          ts: this.reports.TRACKING.ts ? this.reports.TRACKING.ts[index] : 0,
          color: this.reports.TRACKING.gait
            ? this.setColor(this.reports.TRACKING.gait[index])
            : "grey",
          id: index,
        };
      });
    },
    mappedGPSData() {
      if (
        !this.reports?.GPS?.filtered?.ts ||
        !this.reports?.GPS?.resampled?.ts ||
        !this.GPSDatafiles
      ) {
        return [];
      }

      const filteredData = this.reports.GPS.filtered;
      const resampledData = this.reports.GPS.resampled;

      // Get seconds from the timestamps
      const filteredSeconds = filteredData.ts.map((i) => this.getSecond(i));
      const resampledSeconds = resampledData.ts.map((i) => this.getSecond(i));

      const jumpIntervals = this.reports.JUMP.map((jump) => ({
        start: this.getSecond(jump.start),
        end: this.getSecond(jump.end),
      }));

      const result = resampledSeconds.map((second, index) => {
        // Find the closest match for the current second in filtered data
        const filteredIndex = filteredSeconds.findIndex(
          (filteredSecond) => filteredSecond === second
        );

        // Handle the case where the second is not found
        const isValidIndex = filteredIndex !== -1;

        const isInJumpInterval = jumpIntervals.some(
          (interval) => second >= interval.start && second <= interval.end
        );

        // Use raw GPSDatafiles for lat/lng if valid
        const gpsFile = isValidIndex ? this.GPSDatafiles[filteredIndex] : null;

        return {
          x: isValidIndex ? filteredData.x[filteredIndex] : null,
          y: isValidIndex ? filteredData.y[filteredIndex] : null,
          lat: gpsFile ? gpsFile.latitude : null,
          lng: gpsFile ? gpsFile.longitude : null,
          phi: this.reports.TRACKING?.phi
            ? this.convertToDeg(this.reports.TRACKING.phi[index])
            : 0,
          radius: this.reports.TRACKING?.radius
            ? this.reports.TRACKING.radius[index]
            : 0,
          ts: resampledData.ts[index], // Use the timestamp from the resampled data
          color: isInJumpInterval
            ? "black"
            : this.setColor(resampledData.gait[index]),
          gaitType: resampledData.gait[index],
          id: index,
        };
      });

      return result;
    },

    currentMappedData() {
      if (!this.reports || !this.reports.TRACKING?.x) return [];
      else {
        if (this.trackingMode === trackingModeKeys.ML) {
          this.SET_TRACKING_MARKERS_LENGTH(this.reports.TRACKING.x.length);
          return this.mappedMLData;
        } else {
          this.SET_TRACKING_MARKERS_LENGTH(
            this.reports.GPS.resampled.ts.length
          );
          return this.mappedGPSData;
        }
      }
    },
    indexColor() {
      if (this.cutValue) {
        const newMappedDataArray = this.currentMappedData.slice(
          this.cutValue[0],
          this.cutValue[1]
        );
        const maxValue = newMappedDataArray.length - 1;

        return newMappedDataArray[
          this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
        ].color;
      }

      const maxValue = this.currentMappedData.length - 1;
      return this.currentMappedData[
        this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
      ]?.color;
    },
    selectedGaits() {
      return this.movements.filter((i) => (i.checked = true));
    },
    maxRadius() {
      return this.reports.TRACKING.radius
        ? +Math.max(...this.reports.TRACKING.radius).toFixed(2)
        : 0;
    },
    segmentDistance() {
      if (this.reports && this.GPSDatafiles && this.reports.TRACKING.ts) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateGPSSegmentDistance();
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.generateMLSegmentDistance();
        }
      } else if (
        this.reports &&
        this.reports.TRACKING.ts &&
        this.reports.SUMMARY
      ) {
        return this.generateMLSegmentDistance();
      }
      return 0;
    },
    segmentTime() {
      if (this.reports && this.reports.TRACKING.ts) {
        const mainTimestamps = this.reports.TRACKING.ts;

        const startTimestamp = this.cutValue
          ? mainTimestamps[this.cutValue[0]]
          : mainTimestamps[0];

        const endTimestamp = this.cutValue
          ? mainTimestamps[this.cutValue[1] - 1]
          : mainTimestamps[mainTimestamps.length - 1];

        const [startHour, startMinute, startSecond] = startTimestamp
          .split(":")
          .map(Number);
        const [endHour, endMinute, endSecond] = endTimestamp
          .split(":")
          .map(Number);

        // Round the milliseconds part to 3 decimal places
        const startMs = Math.round(
          parseFloat(startTimestamp.split(".")[1]) / 1000
        );
        const endMs = Math.round(parseFloat(endTimestamp.split(".")[1]) / 1000);

        const startTimeInSeconds =
          startHour * 3600 + startMinute * 60 + startSecond + startMs / 1000;
        const endTimeInSeconds =
          endHour * 3600 + endMinute * 60 + endSecond + endMs / 1000;

        const timeDifferenceInSeconds = endTimeInSeconds - startTimeInSeconds;

        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
        const seconds = Math.floor(timeDifferenceInSeconds % 60);

        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`;
      }
      return 0;
    },
    segmentEnergy() {
      if (!this.reports || !this.reports.TRACKING?.gait) return [];
      else {
        let lastGaitIndex = this.reports.TRACKING.gait.length - 1;
        if (this.cutValue) {
          lastGaitIndex = this.cutValue[1];
        }

        const gaitsObject = this.reports.EXPENDITURE_ENERGY_METRICS.raw
          ? this.reports.EXPENDITURE_ENERGY_METRICS.raw
          : this.reports.EXPENDITURE_ENERGY_METRICS;

        const newIndex = this.findLastNonStayElementIndex(
          this.reports.TRACKING.gait,
          lastGaitIndex
        );
        const lastGait = this.reports.TRACKING.gait[newIndex];
        const lastGaitSecond = Math.floor(newIndex / 5);

        const cumulativeEnergy = lastGait
          ? this.sumOfLastElementsForCumulativeEnergy(
              gaitsObject[lastGait],
              lastGaitSecond
            )
          : 0;
        return (cumulativeEnergy / 1000).toFixed(2);
      }
    },
    secondsByGait() {
      if (!Object.keys(this.reports.TRACKING || {}).length) return [];
      let result = this.reports.TRACKING.ts.map((i, idx) => {
        return {
          second: getTrackIndexFromTimestamp(i),
          ts: i,
          gait: this.reports.TRACKING.gait[idx],
          color: this.getGaitColor(this.reports.TRACKING.gait[idx]),
        };
      });

      if (this.cutValue) {
        result = result.slice(this.cutValue[0], this.cutValue[1]);
      }

      return result;
    },
    segmentAvgSpeed() {
      if (this.reports.TRACKING.ts) {
        const [startHour, startMinute, startSecond] = this.segmentTime
          .split(":")
          .map(Number);
        const timeInSeconds = startHour * 3600 + startMinute * 60 + startSecond;

        const avgSpeed = this.segmentDistance / timeInSeconds;
        return Math.round(avgSpeed * 100) / 100;
      }
      return 0;
    },
    segmentMaxSpeed() {
      if (this.GPSDatafiles && this.reports.TRACKING.ts) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateGPSMaxSpeed();
        } else {
          return this.generateMLMaxSpeed();
        }
      } else if (this.reports.TRACKING.ts) {
        return this.generateMLMaxSpeed();
      }
      return 0;
    },
    segmentMaxAcceleration() {
      if (
        this.reports &&
        this.reports.SUMMARY &&
        this.reports.SUMMARY_RESAMPLED
      ) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.SUMMARY_RESAMPLED.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const accelerationArray = this.reports.SUMMARY_RESAMPLED.avg_acc.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxAccValue = Math.max(...accelerationArray);

        return maxAccValue.toFixed(2);
      }
      return 0;
    },
    segmentAvgHeartRate() {
      if (this.reports && this.HRDatafiles && this.HRDatafiles.length) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.HRDatafiles.at(-1).ts
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const arrayOfHR = this.HRDatafiles.slice(
          startSliceSecond,
          endSliceSecond
        );
        const sumOfHR = arrayOfHR.map((i) => i.hr).reduce((a, b) => a + b, 0);

        return Math.round(sumOfHR / arrayOfHR.length);
      }
      return "-";
    },
    segmentMaxHeartRate() {
      if (this.reports && this.HRDatafiles && this.HRDatafiles.length) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.HRDatafiles.at(-1).ts
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const HRArray = this.HRDatafiles.map((i) => i.hr).slice(
          startSliceSecond,
          endSliceSecond
        );

        return Math.max(...HRArray);
      }
      return "-";
    },
    jumpCount() {
      if (!this.reports.JUMP_count) return "-";
      let result = 0;
      let startSliceSecond = 0;
      let endSliceSecond =
        this.getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

      if (this.cutValue) {
        startSliceSecond = Math.round(this.cutValue[0] / 5);
        endSliceSecond = Math.round(this.cutValue[1] / 5);
      }
      this.reports.JUMP.forEach((i) => {
        this.getSecond(i.start) >= startSliceSecond &&
          this.getSecond(i.end) <= endSliceSecond &&
          result++;
      });
      return result;
    },
  },

  watch: {
    multicharts: {
      handler: function (newVal) {
        localStorage.setItem("selected-multicharts", JSON.stringify(newVal));
      },
      deep: true,
    },

    async currentTrackIndex(newTrackIndex) {
      if (!this.isAnimationModalOpen) {
        this.findAndSelectJump(newTrackIndex);
      }
    },

    cutValue(val) {
      const newVal = val
        ? this.currentMappedData.slice(val[0], val[1]).length - 1
        : this.currentMappedData.length - 1;
      this.SET_TRACKING_MARKERS_LENGTH(newVal);

      // Reselect jump if current jump isn't in timeline range
      if (!this.jumpOptions.find((i) => i.id === this.selectedJump.id)) {
        this.jumpOptions?.length &&
          this.selectNewJump({ data: this.jumpOptions[0].label });

        this.closeAnimationModal();
      }
    },

    trackingMode() {
      this.movements.map((i) => (i.checked = true));
      this.cutTrackingValue();
      this.SET_IS_FULLSCREEN_MAP_OPEN(false);
      this.SET_IS_FULLSCREEN_MULTICHART_OPEN(false);
    },
  },
  methods: {
    ...mapMutations([
      "SET_REPORTS_DATA",
      "SET_HORSE_LAMENESS",
      "SET_GPS_DATAFILES",
      "SET_HR_DATAFILES",
      "SET_CURRENT_TRACK_INDEX",
      "SET_TRACKING_SPEED",
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
      "SET_TRACKING_MARKERS_LENGTH",
      "SET_TRACKING_MODE",
      "SET_IS_ANIMATION_MODAL_OPEN",
      "SET_IS_FULLSCREEN_MULTICHART_OPEN",
      "SET_IS_FULLSCREEN_MAP_OPEN",
      "SET_IS_FULLSCREEN_NAVIGATION_OPEN",
      "SET_MAIN_LAYER",
    ]),
    ...mapActions([
      "playTrack",
      "pauseTrack",
      "handleTrackingMode",
      "updateWindowPosition",
      "closeAllFullscreenWindows",
    ]),
    getClass,
    getSecond,
    formatTimestamp,

    openShortcutModal() {
      this.isShortcutModalOpen = true;
      this.closeAllFullscreenWindows();
    },

    findAndSelectJump(newTrackIndex) {
      if (!this.jumpOptions || this.jumpOptions.length === 0) return;

      const closestJump = this.jumpOptions.reduce(
        (closest, jumpOption) => {
          const start = getTrackIndexFromTimestamp(
            formatTimestampToSingleDecimal(jumpOption.jump.start)
          );
          const end = getTrackIndexFromTimestamp(
            formatTimestampToSingleDecimal(jumpOption.jump.end)
          );

          const distanceToStart = Math.abs(newTrackIndex - start);
          const distanceToEnd = Math.abs(newTrackIndex - end);
          const minDistance = Math.min(distanceToStart, distanceToEnd);

          return minDistance < closest.distance
            ? { jump: jumpOption, distance: minDistance }
            : closest;
        },
        { jump: null, distance: Infinity }
      ).jump;

      if (closestJump && closestJump.id !== this.selectedJump?.id) {
        this.isUpdatingJump = true;
        this.selectedJump = {
          ...closestJump.jump,
          id: closestJump.id,
          label: closestJump.label,
        };
        this.isUpdatingJump = false;
      }
    },

    forceRecalculation(key) {
      const keyForUpdate = `${key}DragKey`;
      this[keyForUpdate] += 1;
    },

    async saveCoordinates(x, y, windowKey) {
      const root = document.getElementById("app");
      if (!root) return;

      const modal = this.$refs[windowKey]?.$el;
      if (!modal) return;

      let keyMustBeUpdated = false;

      const rect = modal.getBoundingClientRect();
      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();

      // Left adjusting
      if (rect.x < 8) {
        keyMustBeUpdated = true;
        const shiftX = 8 - rect.x;
        x += shiftX;
      }

      // Top adjusting
      if (rect.y < 8) {
        keyMustBeUpdated = true;
        const shiftY = 8 - rect.y;
        y += shiftY;
      }

      // Right adjusting
      if (rect.right > appWidth - 8) {
        keyMustBeUpdated = true;
        const shiftX = rect.right - (appWidth - 8);
        x -= shiftX;
      }

      // Bottom adjusting
      if (rect.bottom > appHeight - 8) {
        keyMustBeUpdated = true;
        const shiftY = rect.bottom - (appHeight - 8);
        y -= shiftY;
      }

      this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        x: x,
        y: y,
      });

      if (keyMustBeUpdated) {
        await this.$nextTick();
        this.forceRecalculation(windowKey);
      }
    },

    async saveSize(x, y, width, height, windowKey) {
      this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        width,
        height,
      });
      await this.$nextTick();
      this.forceRecalculation(windowKey);
    },

    resetPosition(windowKey) {
      this.updateWindowPosition({
        key: windowKey,
        ...defaultSeparateWindowsPosition[windowKey],
      });
      this.forceRecalculation(windowKey);
    },

    validateInput(event) {
      const value = Number(event.target.value);
      if (value < 20) {
        this.bgHeightValue = 20;
        this.errorMessage = "Minimum value is 20.";
      } else if (value > 1000) {
        this.bgHeightValue = 1000;
        this.errorMessage = "Maximum value is 1000.";
      } else {
        this.bgHeightValue = value;
        this.errorMessage = "";
      }
    },

    openFullscreenNavigation() {
      this.SET_IS_FULLSCREEN_NAVIGATION_OPEN(true);
    },
    closeNavigationWindow() {
      this.SET_IS_FULLSCREEN_NAVIGATION_OPEN(false);
    },
    handleActivated(layerName) {
      this.SET_MAIN_LAYER(layerName);
    },
    closeChartModal() {
      this.SET_IS_FULLSCREEN_MULTICHART_OPEN(false);
    },

    openAnimationModal() {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.SET_IS_ANIMATION_MODAL_OPEN(true);
    },

    closeAnimationModal() {
      this.SET_IS_ANIMATION_MODAL_OPEN(false);
      this.SET_CURRENT_TRACK_INDEX(this.currentTrackIndex + 1); // trigger this for track/map hard updating
      document.addEventListener("keydown", this.handleKeyDown);
    },

    selectNewJump({ data }) {
      const currentJump = this.jumpOptions.find(
        (option) => option.label === data
      );

      if (currentJump) {
        this.selectedJump = {
          ...this.reports.JUMP[currentJump.id - 1],
          id: currentJump.id,
          label: currentJump.label,
        };

        const totalSeconds = this.getSecond(this.selectedJump.start);
        let newTrackIndex; // need add 1 for going to next second
        if (this.cutValue) {
          newTrackIndex =
            (totalSeconds - Math.floor(this.cutValue[0] / 5)) * 5 - 1;
        } else {
          newTrackIndex = totalSeconds * 5 + 1;
        }

        this.SET_CURRENT_TRACK_INDEX(newTrackIndex);
      }
    },

    setLegendData(newVal) {
      this.legendWithData = newVal;
    },

    getGaitColor(gaitType) {
      switch (gaitType) {
        case "walk":
          return "#1AB0B0";
        case "trot":
          return "#FFCD4B";
        case "gallop":
          return "#F85C7F";
        default:
          return "silver";
      }
    },
    findLastNonStayElementIndex(arr, endIndex) {
      for (let i = endIndex; i >= 0; i--) {
        if (arr[i] !== "stay") {
          return i;
        }
      }
      return -1;
    },
    sumOfLastElementsForCumulativeEnergy(obj, lastGaitSecond) {
      return Object.values(obj).reduce((sum, nestedObj) => {
        if (
          nestedObj.cumulative_energy &&
          Array.isArray(nestedObj.cumulative_energy) &&
          nestedObj.cumulative_energy.length > 0
        ) {
          const { cumulative_energy, ts } = nestedObj;
          for (let i = ts.length - 1; i >= 0; i--) {
            if (this.getSecond(ts[i]) < lastGaitSecond) {
              return sum + cumulative_energy[i];
            }
          }
        }
        return sum;
      }, 0);
    },
    handleSwitchTab(index) {
      this.tabs.map((i) => (i.current = false));
      this.tabs[index].current = true;
      this.currentTabIdx = index;
    },
    convertToDeg(rad) {
      const pi = Math.PI;
      return rad * (180 / pi);
    },
    setColor(type) {
      if (type === "stay") return "grey";
      else if (type === "walk") return "#1AB0B0";
      else if (type === "trot") return "#FFCD4B";
      else if (type === "gallop") return "#F85C7F";
    },
    cutTrackingValue() {
      this.SET_CURRENT_TRACK_INDEX(0);
      const cutValueArray = [];
      if (this.fromToCutValue.length) {
        cutValueArray.push(this.fromToCutValue[0] || 0);
        cutValueArray.push(
          this.fromToCutValue[1] || this.currentMappedData.length - 1
        );
      }
      this.cutValue = this.fromToCutValue.length ? cutValueArray : null;
    },
    changeCutValue(val) {
      this.fromToCutValue = [
        this.currentMappedData[val[0]].id ?? 0,
        this.currentMappedData[val[1] - 1]?.id ?? 0,
      ];
    },
    generateGPSMaxSpeed() {
      if (this.reports.GPS?.resampled) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.GPS.resampled.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const speedArray = this.reports.GPS.resampled.speed.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxSpeedValue = Math.max(...speedArray);

        return maxSpeedValue.toFixed(2);
      }
      return 0;
    },
    generateMLMaxSpeed() {
      if (this.reports.SUMMARY_RESAMPLED) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.SUMMARY_RESAMPLED.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const speedArray = this.reports.SUMMARY_RESAMPLED.avg_vel.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxSpeedValue = Math.max(...speedArray);

        return maxSpeedValue.toFixed(2);
      }
      return 0;
    },
    generateGPSSegmentDistance() {
      if (this.reports.GPS?.resampled) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.GPS.resampled.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const totalDistance = this.reports.GPS.resampled.distance
          .slice(startSliceSecond, endSliceSecond)
          .reduce((a, b) => a + b, 0);

        return Math.ceil(totalDistance);
      }
      return 0;
    },

    generateMLSegmentDistance() {
      if (this.reports.SUMMARY_RESAMPLED) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.SUMMARY_RESAMPLED.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const totalDistance = this.reports.SUMMARY_RESAMPLED.stride_length
          .slice(startSliceSecond, endSliceSecond)
          .reduce((a, b) => a + b, 0);

        return Math.round(totalDistance);
      }
      return 0;
    },

    handleKeyDown(event) {
      const { key, shiftKey } = event;

      const setCurrentTrackIndex = (newValue) => {
        const maxValue = this.reports.TRACKING.x.length - 1;
        this.SET_CURRENT_TRACK_INDEX(Math.min(maxValue, Math.max(0, newValue)));
      };

      switch (key) {
        case "ArrowLeft":
          setCurrentTrackIndex(
            shiftKey ? this.currentTrackIndex - 20 : this.currentTrackIndex - 1
          );
          break;
        case "ArrowRight":
          setCurrentTrackIndex(
            shiftKey ? this.currentTrackIndex + 20 : this.currentTrackIndex + 1
          );
          break;
        case " ":
          event.preventDefault();
          if (this.isTrackStarted && !this.isTrackPaused) {
            this.pauseTrack();
          } else if (!this.isTrackStarted && this.isTrackPaused) {
            this.playTrack();
          }
          break;
        case "0":
          this.SET_CURRENT_TRACK_INDEX(0);
          break;
        case "1":
          this.SET_TRACKING_SPEED(1);
          break;
        case "2":
          this.SET_TRACKING_SPEED(2);
          break;
        case "3":
          this.SET_TRACKING_SPEED(4);
          break;
        case "4":
          this.SET_TRACKING_SPEED(10);
          break;
        case "5":
          this.SET_TRACKING_SPEED(20);
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    let selectedTrackingMode = localStorage.getItem("tracking-mode");
    if (!selectedTrackingMode) {
      selectedTrackingMode = trackingModeKeys.ML;
      localStorage.setItem("tracking-mode", selectedTrackingMode);
    }
    this.handleTrackingMode({ data: selectedTrackingMode });

    const defaultMulticharts = [
      {
        title: this.$t("tracking.Speed"),
        scaleId: "y",
        checked: true,
        color: "#F1C40F",
      },
      {
        title: this.$t("tracking.Impulse"),
        scaleId: "y1",
        checked: false,
        color: "#FF6B00FF",
      },
      {
        title: this.$t("tracking.Rhythm"),
        scaleId: "y2",
        checked: false,
        color: "#FF69B4",
      },
      {
        title: this.$t("tracking.Stride length"),
        scaleId: "y3",
        checked: false,
        color: "#6495ED",
      },
      {
        title: this.$t("tracking.Acceleration"),
        scaleId: "y4",
        checked: false,
        color: "#8A2BE2",
      },
      {
        title: this.$t("tracking.Step time"),
        scaleId: "y5",
        checked: false,
        color: "#8FBC8F",
      },
      {
        title: this.$t("tracking.Rhythm deviation"),
        scaleId: "y6",
        checked: false,
        color: "#898121",
      },
      {
        title: this.$t("tracking.Heart rate"),
        scaleId: "y7",
        checked: false,
        color: "#E74C3C",
      },
    ];

    let selectedMulticharts = localStorage.getItem("selected-multicharts");
    if (selectedMulticharts) {
      selectedMulticharts = JSON.parse(selectedMulticharts);
    } else {
      selectedMulticharts = defaultMulticharts;
      localStorage.setItem(
        "selected-multicharts",
        JSON.stringify(selectedMulticharts)
      );
    }
    this.multicharts = selectedMulticharts;
  },
  async mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    if (this.jumpOptions?.length) {
      this.selectNewJump({ data: this.jumpOptions[0].label });
      this.SET_CURRENT_TRACK_INDEX(0);
    }
  },
  beforeUnmount() {
    this.closeAllFullscreenWindows();
    document.removeEventListener("keydown", this.handleKeyDown);
    this.SET_REPORTS_DATA(null);
    this.SET_HORSE_LAMENESS(null);
    this.SET_GPS_DATAFILES(null);
    this.SET_HR_DATAFILES(null);
    this.SET_CURRENT_TRACK_INDEX(0);
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
:deep(#jumps) {
  height: 40px;
  width: auto;
  font-size: 14px;
}

.modal {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vdr {
  border: none;
}
</style>
