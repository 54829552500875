<template>
  <div
    class="mx-auto w-full min-h-[350px] overflow-hidden"
    :class="{
      'lg:max-w-[42%]':
        !isFullscreenMultichartOpen ||
        (isFullscreenMultichartOpen && currentTabIdx !== 0),
    }"
    :style="{ width: isFullscreenMapOpen ? '0px' : '100%' }"
  >
    <div v-show="isTrackingDataExist">
      <div class="flex items-center">
        <div class="mb-2 mr-4">
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            id="isTrackColored"
            v-model="isTrackColored"
          />
          <label
            for="isTrackColored"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span> {{ $t("tracking.Show gaits") }} </span>
          </label>
        </div>
        <div class="mb-2 mr-2" v-show="trackingMode === trackingModeKeys.GPS">
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            id="isMapVisible"
            v-model="isMapVisible"
          />
          <label
            for="isMapVisible"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span> {{ $t("tracking.Show maps") }} </span>
          </label>
        </div>
      </div>

      <div class="relative">
        <div v-show="isMapVisible" class="relative h-[450px]">
          <GoogleMaps
            v-if="!isFullscreenMapOpen"
            mapId="miniMap"
            :tracking="tracking"
            :selectedGaits="selectedGaits"
            :trackingMarkersLength="trackingMarkersLength"
            :isTrackColored="isTrackColored"
            :fromToCutValue="fromToCutValue"
            :cutValue="cutValue"
          />
        </div>

        <div
          id="tracking-chart"
          v-show="!isMapVisible"
          class="absolute top-0 left-0 bottom-0 right-0 z-1"
        ></div>
      </div>
    </div>

    <vue-draggable-resizable
      v-if="isFullscreenMapOpen"
      :key="mapDragKey"
      ref="map"
      :active="true"
      @dragStop="(x, y) => saveCoordinates(x, y, 'map')"
      @resizeStop="
        (left, top, width, height) => saveSize(left, top, width, height, 'map')
      "
      :x="separateWindowPositions.map.x"
      :y="separateWindowPositions.map.y"
      :w="separateWindowPositions.map.width"
      :h="separateWindowPositions.map.height"
      :drag-handle="'.modal-header'"
      :drag-cancel="'.drag-cancel'"
      :resizable="true"
      :minWidth="450"
      :minHeight="450"
      :handles="['tr', 'mr', 'br', 'bl', 'ml']"
      @activated="handleActivated"
      :style="{ zIndex: mainLayer === 'maps' ? 53 : 52 }"
      @close="handleCancel"
    >
      <div class="modal h-full">
        <div
          class="modal-header bg-gray-800 text-white p-2 cursor-move flex justify-between items-center"
        >
          <div>
            <span>Google Maps</span>
            <button
              @click="resetPosition"
              class="drag-cancel ml-4 p-2 cursor-pointer bg-white text-gray-800 rounded-md hover:bg-gray-100"
            >
              Reset position
            </button>
          </div>
          <button
            class="drag-cancel text-red-500 hover:text-red-700 font-bold"
            @click="handleCancel"
          >
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="modal-content relative h-full-available">
          <GoogleMaps
            mapId="fullscreenMap"
            :tracking="tracking"
            :selectedGaits="selectedGaits"
            :trackingMarkersLength="trackingMarkersLength"
            :isTrackColored="isTrackColored"
            :fromToCutValue="fromToCutValue"
            :cutValue="cutValue"
          />
        </div>
      </div>
    </vue-draggable-resizable>

    <div v-show="!isTrackingDataExist">{{ $t("Data not found") }}</div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  defaultSeparateWindowsPosition,
  movements,
  trackingModeKeys,
} from "@/components/training/constants";
import { getClass } from "@/components/training/helpers";
import GoogleMaps from "@/components/training/tracking/GoogleMaps.vue";
import VueDraggableResizable from "vue-draggable-resizable";
import { XMarkIcon } from "@heroicons/vue/24/outline";

export default {
  name: "HorseTrackingComponent",
  components: {
    XMarkIcon,
    VueDraggableResizable,
    GoogleMaps,
  },
  props: {
    tracking: Array,
    fromToCutValue: Array,
    lengthPreviousValues: Number,
    selectedGaits: Array,
    trackingMarkersLength: Number,
    cutValue: Array,
    currentTabIdx: Number,
  },
  data() {
    return {
      movements,
      trackingModeKeys,

      arr: [],
      mappedData: [],
      gaitTrackingMarkers: [],
      fromToCutMappedValue: [],
      trackingPoints: {},
      isTrackColored: true,
      isMapVisible: false,
      selectedGaitColors: [],
      marker: null,
      currentPositionMarker: null,
      isTrackingMarkersLengthChanged: false,
      trackPlanCoordinates: [],

      mapDragKey: 0,
    };
  },
  watch: {
    currentTrackIndex() {
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
    },

    async tracking() {
      if (this.trackingMode !== this.trackingModeKeys.GPS) {
        this.isMapVisible = false;
      }

      this.setTrackingPoints();
      await Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
      this.resetZoom();
    },

    fromToCutValue() {
      this.fromToCutMappedValue = this.fromToCutValue;
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
    },

    historyArray() {
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
    },

    selectedGaits: {
      handler: async function (newVal) {
        this.selectedGaitColors = newVal
          .filter((i) => i.checked)
          .map((i) => i.backgroundColor);
        Plotly.react("tracking-chart", this.data, this.layout, {
          displayModeBar: false,
          scrollZoom: true,
        });
      },
      deep: true,
    },

    isFullscreenMultichartOpen() {
      this.resetZoom();
    },
    currentTabIdx() {
      this.resetZoom();
    },
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "GPSDatafiles",
      "reports",
      "trackingMode",
      "isFullscreenMapOpen",
      "isFullscreenMultichartOpen",
      "mainLayer",
      "separateWindowPositions",
    ]),
    isTrackingDataExist() {
      return this.tracking && this.tracking.length > 0;
    },
    layout() {
      return { ...this.getDefaultLayout() };
    },
    data() {
      if (!this.isTrackingDataExist) return null;
      const maxValue = this.tracking.length - 1;
      let marker = {
        x: [
          this.tracking[
            this.currentTrackIndex > this.tracking.length - 1
              ? maxValue
              : this.currentTrackIndex
          ].x,
        ],
        y: [
          this.tracking[
            this.currentTrackIndex > this.tracking.length - 1
              ? maxValue
              : this.currentTrackIndex
          ].y,
        ],
        mode: "markers",
        name: "Scatter",
        marker: {
          color: "red",
          size: 8,
        },
      };
      if (this.fromToCutMappedValue.length) {
        let sliceFromMarkerId = this.tracking.find(
          (i) => i.id === this.fromToCutMappedValue[0]
        );
        let sliceToMarkerId = this.tracking.find(
          (i) => i.id === this.fromToCutMappedValue[1]
        );

        let sliceFromMarkerIndex = this.tracking.findIndex(
          (i) => i.id === this.fromToCutMappedValue[0]
        );
        let sliceToMarkerIndex = this.tracking.findIndex(
          (i) => i.id === this.fromToCutMappedValue[1]
        );

        let SliceFromMarker = {
          x: [sliceFromMarkerId ? sliceFromMarkerId.x : this.tracking[0].x],
          y: [sliceFromMarkerId ? sliceFromMarkerId.y : this.tracking[0].y],
          mode: "markers",
          name: "Scatter",
          marker: {
            color: "grey",
            size: 4,
          },
        };
        let SliceToMarker = {
          x: [
            sliceToMarkerId
              ? sliceToMarkerId.x
              : this.tracking[this.tracking.length - 1].x,
          ],
          y: [
            sliceToMarkerId
              ? sliceToMarkerId.y
              : this.tracking[this.tracking.length - 1].y,
          ],
          mode: "markers",
          name: "Scatter",
          marker: {
            color: "grey",
            size: 4,
          },
        };

        let sliceFromTrack = {
          x: this.trackingPoints.x.slice(
            0,
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0
          ),
          y: this.trackingPoints.y.slice(
            0,
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#d2d1d1",
            width: 0.5,
          },
        };
        let sliceToTrack = {
          x: this.trackingPoints.x.slice(
            sliceToMarkerIndex > -1 ? sliceToMarkerIndex : 0,
            sliceToMarkerIndex > -1 ? this.trackingPoints.x.length - 1 : 0
          ),
          y: this.trackingPoints.y.slice(
            sliceToMarkerIndex > -1 ? sliceToMarkerIndex : 0,
            sliceToMarkerIndex > -1 ? this.trackingPoints.y.length - 1 : 0
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#d2d1d1",
            width: 0.5,
          },
        };

        let track = {
          x: this.trackingPoints.x.slice(
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0,
            sliceToMarkerIndex > -1
              ? sliceToMarkerIndex
              : this.trackingPoints.x.length - 1
          ),
          y: this.trackingPoints.y.slice(
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0,
            sliceToMarkerIndex > -1
              ? sliceToMarkerIndex
              : this.trackingPoints.y.length - 1
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#5b5959",
            width: 0.5,
          },
        };
        return [
          ...this.previousValues,
          SliceFromMarker,
          SliceToMarker,
          marker,
          sliceFromTrack,
          sliceToTrack,
          track,
        ];
      } else {
        let track = {
          x: this.trackingPoints.x,
          y: this.trackingPoints.y,
          mode: "lines",
          line: {
            dash: "dash",
            color: "#5b5959",
            width: 0.5,
          },
        };
        return [...this.previousValues, track, marker];
      }
    },

    historyArray() {
      if (!this.isTrackColored) {
        const val =
          this.currentTrackIndex > this.lengthPreviousValues
            ? this.lengthPreviousValues
            : this.currentTrackIndex;
        return this.tracking.slice(
          this.currentTrackIndex - val,
          this.currentTrackIndex
        );
      }
      return this.tracking;
    },

    previousValues() {
      let type = [];
      let res = [];

      this.historyArray.map((i, index) => {
        if (
          this.historyArray[index + 1] &&
          i.color === this.historyArray[index + 1].color
        ) {
          type.push(i);
        } else if (
          !this.historyArray[index + 1] ||
          i.color !== this.historyArray[index + 1].color
        ) {
          type.push(i);
          if (this.selectedGaitColors.includes(type[0].color)) {
            res.push({
              mode: "lines",
              x: type.map((i) => i.x),
              y: type.map((i) => i.y),
              line: {
                color: this.selectedGaitColors.includes(type[0].color)
                  ? type[0].color
                  : "silver",
                width: 0.8,
              },
            });
          }

          type = [];
        }
      });
      return res;
    },
  },
  methods: {
    ...mapMutations(["SET_IS_FULLSCREEN_MAP_OPEN", "SET_MAIN_LAYER"]),
    ...mapActions(["updateWindowPosition"]),
    getClass,

    forceRecalculation(key) {
      const keyForUpdate = `${key}DragKey`;
      this[keyForUpdate] += 1;
    },

    async saveCoordinates(x, y, windowKey) {
      const root = document.getElementById("app");
      if (!root) return;

      const modal = this.$refs[windowKey]?.$el;
      if (!modal) return;

      let keyMustBeUpdated = false;

      const rect = modal.getBoundingClientRect();
      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();

      // Left adjusting
      if (rect.x < 8) {
        keyMustBeUpdated = true;
        const shiftX = 8 - rect.x;
        x += shiftX;
      }

      // Top adjusting
      if (rect.y < 8) {
        keyMustBeUpdated = true;
        const shiftY = 8 - rect.y;
        y += shiftY;
      }

      // Right adjusting
      if (rect.right > appWidth - 8) {
        keyMustBeUpdated = true;
        const shiftX = rect.right - (appWidth - 8);
        x -= shiftX;
      }

      // Bottom adjusting
      if (rect.bottom > appHeight - 8) {
        keyMustBeUpdated = true;
        const shiftY = rect.bottom - (appHeight - 8);
        y -= shiftY;
      }

      await this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        x: x,
        y: y,
      });

      if (keyMustBeUpdated) {
        await this.$nextTick();
        this.forceRecalculation(windowKey);
      }
    },

    async saveSize(x, y, width, height, windowKey) {
      await this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        width,
        height,
      });
      await this.$nextTick();
      this.forceRecalculation(windowKey);
    },

    resetPosition() {
      this.updateWindowPosition({
        key: "map",
        ...defaultSeparateWindowsPosition.map,
      });
    },

    handleActivated() {
      this.SET_MAIN_LAYER("maps");
    },
    handleCancel() {
      this.SET_IS_FULLSCREEN_MAP_OPEN(false);
    },
    getDefaultLayout() {
      return {
        dragmode: "pan",
        autosize: true,
        showlegend: false,
        hovermode: false,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        margin: {
          l: 50,
          r: 10,
          b: 40,
          t: 10,
        },
        xaxis: {
          title: {
            text: this.$t("tracking.X Axis in meters"),
            font: {
              family: "sans-serif, Manrope",
              size: 14,
              color: "lightgrey",
            },
          },
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: true,
          autorange: true,
          nticks: 10,
          domain: [0, 1],
        },
        yaxis: {
          title: {
            text: this.$t("tracking.Y Axis in meters"),
            font: {
              family: "sans-serif, Manrope",
              size: 14,
              color: "lightgrey",
            },
          },
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: true,
          autorange: true,
          scaleanchor: "x",
          nticks: 10,
          domain: [0, 1],
        },
      };
    },

    resetZoom() {
      const defaultLayout = this.getDefaultLayout();
      Plotly.relayout("tracking-chart", defaultLayout).then(() => {
        const plotDiv = document.getElementById("tracking-chart");
        const xAxisRange = plotDiv.layout.xaxis.range;
        const yAxisRange = plotDiv.layout.yaxis.range;

        // Calculate new ranges based on the desired zoom out level (0.85x)
        const zoomOutFactor = 0.15; // 15% zoom out
        const newXAxisRange = [
          xAxisRange[0] - ((xAxisRange[1] - xAxisRange[0]) * zoomOutFactor) / 2,
          xAxisRange[1] + ((xAxisRange[1] - xAxisRange[0]) * zoomOutFactor) / 2,
        ];
        const newYAxisRange = [
          yAxisRange[0] - ((yAxisRange[1] - yAxisRange[0]) * zoomOutFactor) / 2,
          yAxisRange[1] + ((yAxisRange[1] - yAxisRange[0]) * zoomOutFactor) / 2,
        ];

        Plotly.relayout("tracking-chart", {
          "xaxis.range": newXAxisRange,
          "yaxis.range": newYAxisRange,
        });
      });
    },

    setTrackingPoints() {
      this.trackingPoints.y = this.tracking.map((i) => i.y);
      this.trackingPoints.x = this.tracking.map((i) => i.x);
    },
  },

  mounted() {
    if (this.isTrackingDataExist) {
      this.selectedGaitColors = this.selectedGaits
        .filter((i) => i.checked)
        .map((i) => i.backgroundColor);

      this.setTrackingPoints();
      Plotly.newPlot("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
      this.resetZoom();
    }
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;

  button {
    padding: 5px 10px;
    margin: 0 5px;
    background: #fff;
    color: #666;
  }
}

.modal {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vdr {
  border: none;
}
</style>
