<template>
  <div class="w-full">
    <div class="flex items-center justify-between w-full gap-4 mb-4">
      <div class="flex items-center gap-4">
        <label for="zoom-level" class="text-sm font-medium">Zoom Level:</label>
        <select
          id="zoom-level"
          v-model="selectedZoomPercent"
          @change="handleZoomLevelChange"
          class="border rounded px-2 py-1 text-sm"
        >
          <option v-for="zoom in zoomPercentages" :key="zoom" :value="zoom">
            {{ zoom }}%
          </option>
        </select>
      </div>
    </div>

    <LegendComponent :options="multicharts" />
  </div>

  <div
    class="w-full min-h-[450px] h-full relative mx-auto p-4 flex bg-white shadow rounded-lg"
  >
    <button
      v-if="!isFullscreenMultichartOpen"
      @click.prevent="handleClick"
      class="absolute top-2 right-2 z-10 flex items-center"
    >
      <ArrowsPointingOutIcon class="h-6 w-6" aria-hidden="true" />
    </button>
    <MultichartComponent
      v-if="multichartData"
      :chartData="multichartData"
      :secondsByGait="decimatedSecondsByGait"
      :getMultichartColor="getMultichartColor"
      :visibleChartWasUpdatedFlag="visibleChartWasUpdatedFlag"
      @updateDecimationData="updateDecimationData"
      @setLocalZoom="setLocalZoom"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MultichartComponent from "@/components/training/tracking/trackingComponents/multiChart/MultichartComponent.vue";
import { movements, trackingModeKeys } from "@/components/training/constants";
import {
  decimateData,
  formatTimestampToSingleDecimal,
  getBackgroundColorByGait,
  getTrackIndexFromTimestamp,
  setLocalZoomData,
  syncDatasetsByX,
} from "@/components/training/tracking/trackingComponents/multiChart/multiChartUtils";
import LegendComponent from "@/components/training/tracking/trackingComponents/multiChart/LegendComponent.vue";
import { ArrowsPointingOutIcon } from "@heroicons/vue/24/outline";

export default {
  name: "MultichartWrapper",
  components: {
    ArrowsPointingOutIcon,
    LegendComponent,
    MultichartComponent,
  },
  inject: ["setLegendData"],
  props: {
    mappedData: Array,
    selectedGaits: Array,
    cutValue: Array,
    secondsByGait: Array,
    trackingMarkersLength: Number,
    multicharts: Array,
  },
  data() {
    return {
      multichartData: null,
      decimatedSecondsByGait: [],
      decimationSamples: 100, // Number of points after decimation
      originalFullData: [],
      chartRef: null,
      visibleChartWasUpdatedFlag: false,
      zoomPercentages: [0.2, 0.5, 1, 5, 10, 25, 50, 75, 100], // Zoom levels in percentages
      selectedZoomPercent: 100, // Default zoom level (100% of the total range)
    };
  },

  watch: {
    multicharts: {
      handler: function () {
        this.prepareInitialChartData();
        this.visibleChartWasUpdatedFlag = !this.visibleChartWasUpdatedFlag;
        this.setNewLegendData(this.currentTrackIndex);
      },
      deep: true,
    },
    selectedGaits: {
      handler: function () {
        this.visibleChartWasUpdatedFlag = !this.visibleChartWasUpdatedFlag;
        this.decimatedSecondsByGait = this.generateGeneralizedGaits();
      },
      deep: true,
    },
    currentTrackIndex(newVal) {
      this.setNewLegendData(newVal);
    },
    async cutValue(newVal) {
      this.localZoom = {
        x: { min: 0, max: newVal[1] },
      };
      this.prepareInitialChartData();
      this.visibleChartWasUpdatedFlag = !this.visibleChartWasUpdatedFlag;
      this.setNewLegendData(this.currentTrackIndex);
    },
    trackingMode() {
      this.prepareInitialChartData();
      this.visibleChartWasUpdatedFlag = !this.visibleChartWasUpdatedFlag;
      this.setNewLegendData(this.currentTrackIndex);
    },
  },

  computed: {
    ...mapGetters([
      "reports",
      "GPSDatafiles",
      "HRDatafiles",
      "currentTrackIndex",
      "trackingMode",
      "localZoom",
      "isFullscreenMultichartOpen",
    ]),

    trainingTime() {
      let result = this.mappedData.map((i) => i.ts);
      if (this.cutValue) {
        result = result.slice(this.cutValue[0], this.cutValue[1]);
      }
      return result;
    },

    speed() {
      if (this.GPSDatafiles) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateSpeedByGPS();
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.generateSpeedByML();
        }
      } else if (Object.keys(this.reports.SUMMARY || {}).length) {
        return this.generateSpeedByML();
      }
      return [];
    },

    impulse() {
      if (!Object.keys(this.reports.IMPULSE || {}).length) return [];

      return this.reports.IMPULSE.timestamp.map((i, idx) => {
        return {
          x: formatTimestampToSingleDecimal(i),
          y: this.reports.IMPULSE.impulse[idx].toFixed(2),
        };
      });
    },

    rhythm() {
      if (!Object.keys(this.reports.RHYTHM || {}).length) return [];

      return this.reports.RHYTHM.timestamp.map((i, idx) => {
        return {
          x: formatTimestampToSingleDecimal(i),
          y: this.reports.RHYTHM.rhythm[idx].toFixed(2),
        };
      });
    },

    strideLength() {
      if (!Object.keys(this.reports.SUMMARY_RESAMPLED || {}).length) return [];

      return this.reports.SUMMARY_RESAMPLED.ts.map((i, idx) => {
        return {
          x: i,
          y: this.reports.SUMMARY_RESAMPLED.stride_length[idx].toFixed(2),
        };
      });
    },
    acceleration() {
      if (!Object.keys(this.reports.SUMMARY_RESAMPLED || {}).length) return [];

      return this.reports.SUMMARY_RESAMPLED.ts.map((i, idx) => {
        return {
          x: i,
          y: this.reports.SUMMARY_RESAMPLED.avg_acc[idx].toFixed(2),
        };
      });
    },
    stepTime() {
      if (!Object.keys(this.reports.SUMMARY || {}).length) return [];

      return this.reports.SUMMARY.start.map((i, idx) => {
        return {
          x: formatTimestampToSingleDecimal(i),
          y: this.reports.SUMMARY.duration[idx].toFixed(2),
        };
      });
    },
    rhythmDeviation() {
      if (!Object.keys(this.reports.RHYTHM || {}).length) return [];

      return this.reports.RHYTHM.timestamp.map((i, idx) => {
        return {
          x: formatTimestampToSingleDecimal(i),
          y: this.reports.RHYTHM.rhythm_deviation[idx].toFixed(2),
        };
      });
    },
    heartRate() {
      if (this.HRDatafiles) {
        return this.generateHeartRate();
      }
      return [];
    },
  },

  methods: {
    ...mapMutations([
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
      "SET_IS_FULLSCREEN_MULTICHART_OPEN",
      "SET_MULTICHART_LOCAL_ZOOM",
    ]),

    handleZoomLevelChange() {
      const totalRange = this.trainingTime.length;
      const zoomLevel = this.selectedZoomPercent / 100;

      if (zoomLevel === 1) {
        // 100% zoom for full data
        this.localZoom.x = {
          min: 0,
          max: totalRange,
        };
      } else {
        const visibleRange = Math.max(1, totalRange * zoomLevel); // Minimum 1 point visible
        const centerIndex = this.currentTrackIndex; // Index for zooming in center (around currentTrackIndex)
        const halfRange = Math.floor(visibleRange / 2);

        // Calculate new X1 and X2
        const newMin = Math.max(0, centerIndex - halfRange);
        const newMax = Math.min(totalRange, centerIndex + halfRange);
        // Update data
        this.localZoom.x = {
          min: newMin,
          max: newMax,
        };
      }

      this.visibleChartWasUpdatedFlag = !this.visibleChartWasUpdatedFlag;
    },

    handleCancel() {
      this.SET_IS_TRACK_STARTED(false);
      this.SET_IS_TRACK_PAUSED(true);
      this.SET_IS_FULLSCREEN_MULTICHART_OPEN(false);
    },
    handleClick() {
      this.SET_IS_TRACK_STARTED(false);
      this.SET_IS_TRACK_PAUSED(true);
      this.SET_IS_FULLSCREEN_MULTICHART_OPEN(true);
    },

    getMultichartColor(id) {
      return this.multicharts.find((i) => i.scaleId === id)?.color || "gray";
    },

    setNewLegendData(valueIndex) {
      const newData = this.originalFullData.datasets.map((dataset) => {
        return {
          scaleId: dataset.yAxisID,
          color: dataset.backgroundColor,
          title: dataset.label,
          currentValue: dataset.data[valueIndex].y || "-",
        };
      });
      this.setLegendData(newData);
    },

    setLocalZoom(chart) {
      const newValue = {
        ...setLocalZoomData(chart),
      };
      this.SET_MULTICHART_LOCAL_ZOOM(newValue);
    },

    async updateDecimationData(chart, newMin = null, newMax = null) {
      let minValue = newMin !== null ? newMin : this.localZoom.x.min;
      let maxValue = newMax !== null ? newMax : this.localZoom.x.max;

      if (!this.originalFullData || !this.originalFullData.datasets) return;

      // quick access to gait by index
      const gaitMap = new Map(
        this.secondsByGait.map((item, index) => [index, item.gait])
      );

      const selectedGaitIds = movements
        .filter((movement) => movement.checked)
        .map((movement) => movement.id);

      const updatedDatasets = this.originalFullData.datasets.map((dataset) => {
        // TODO optimize Rhythm dataset without decimation
        if (dataset.yAxisID === "y2") {
          return dataset;
        }

        const filteredData = [];
        for (const point of dataset.data) {
          const xVal = this.cutValue
            ? getTrackIndexFromTimestamp(point.x) - this.cutValue[0]
            : getTrackIndexFromTimestamp(point.x);

          // check if the point is in visible range
          if (xVal >= minValue && xVal <= maxValue) {
            const gaitValue = gaitMap.get(xVal);
            if (selectedGaitIds.includes(gaitValue)) {
              filteredData.push(point);
            } else {
              filteredData.push({ ...point, y: null }); // hide point
            }
          }
        }

        // set decimation
        const dynamicSamples = Math.max(
          Math.floor(
            (this.decimationSamples * filteredData.length) /
              (maxValue - minValue)
          ),
          10
        );
        const decimatedData =
          filteredData.length > dynamicSamples
            ? decimateData(filteredData, dynamicSamples)
            : filteredData;

        return { ...dataset, data: decimatedData };
      });

      this.multichartData = {
        ...this.originalFullData,
        datasets: updatedDatasets,
      };

      this.setLocalZoom(chart);

      chart.update("none");
    },

    getDecimatedChartData(chartData) {
      const decimatedDatasets = chartData.datasets.map((dataset) => {
        const decimatedData = decimateData(
          dataset.data,
          dataset.yAxisID === "y2"
            ? dataset.data.length
            : this.decimationSamples
        );
        return { ...dataset, data: decimatedData };
      });
      return { ...chartData, datasets: decimatedDatasets };
    },

    generateGeneralizedGaits() {
      const intervals = [];
      const jumps = (this.reports.JUMP || []).map((jump) => ({
        start: jump.start,
        end: jump.end,
      }));
      const JUMP_LINE_COLOR = "rgba(0, 0, 0, 0.7)";

      let startSecond = this.secondsByGait[0]?.ts || null;
      let currentGait = this.secondsByGait[0]?.gait || null;
      let currentColor = getBackgroundColorByGait(currentGait);
      let insideJump = false;

      // Process intervals
      for (let i = 0; i < this.secondsByGait.length - 1; i++) {
        const currentSecond = this.secondsByGait[i].ts;
        const nextGait = this.secondsByGait[i + 1].gait;
        const nextColor = getBackgroundColorByGait(nextGait);

        const isJump =
          jumps.some(
            (jump) => currentSecond >= jump.start && currentSecond <= jump.end
          ) && movements.find((i) => i.id === "jump")?.checked;

        if (isJump) {
          const jumpMoment = currentSecond;

          // Push vertical line for jump
          intervals.push({
            start: jumpMoment,
            end: jumpMoment,
            gait: "jump",
            color: JUMP_LINE_COLOR,
            isLine: true,
          });

          if (!insideJump) {
            if (startSecond !== null) {
              intervals.push({
                start: startSecond,
                end: currentSecond,
                gait: currentGait,
                color: currentColor,
              });
            }
            startSecond = currentSecond;
            currentColor = JUMP_LINE_COLOR;
            insideJump = true;
          }
        } else {
          if (insideJump) {
            intervals.push({
              start: startSecond,
              end: currentSecond,
              gait: "jump",
              color: JUMP_LINE_COLOR,
            });
            startSecond = currentSecond;
            currentGait = nextGait;
            currentColor = nextColor;
            insideJump = false;
          }

          if (nextGait !== currentGait) {
            intervals.push({
              start: startSecond,
              end: currentSecond,
              gait: currentGait,
              color: currentColor,
            });
            startSecond = currentSecond;
            currentGait = nextGait;
            currentColor = nextColor;
          }
        }
      }

      // Add the last interval
      intervals.push({
        start: startSecond,
        end: this.secondsByGait[this.secondsByGait.length - 1].second,
        gait: currentGait,
        color: currentColor,
      });

      return intervals;
    },

    prepareInitialChartData() {
      const initialChartData = {
        labels: this.trainingTime,
        datasets: [
          {
            label: this.$t("tracking.Speed"),
            data: this.speed,
            borderColor: this.getMultichartColor("y"),
            backgroundColor: this.getMultichartColor("y"),
            yAxisID: "y",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Impulse"),
            data: this.impulse,
            borderColor: this.getMultichartColor("y1"),
            backgroundColor: this.getMultichartColor("y1"),
            yAxisID: "y1",
            display: false,
          },
          {
            elements: {
              point: {
                radius: 0,
              },
            },
            label: this.$t("tracking.Rhythm"),
            data: this.rhythm,
            borderColor: this.getMultichartColor("y2"),
            backgroundColor: this.getMultichartColor("y2"),
            yAxisID: "y2",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Stride length"),
            data: this.strideLength,
            borderColor: this.getMultichartColor("y3"),
            backgroundColor: this.getMultichartColor("y3"),
            yAxisID: "y3",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Acceleration"),
            data: this.acceleration,
            borderColor: this.getMultichartColor("y4"),
            backgroundColor: this.getMultichartColor("y4"),
            yAxisID: "y4",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Step time"),
            data: this.stepTime,
            borderColor: this.getMultichartColor("y5"),
            backgroundColor: this.getMultichartColor("y5"),
            yAxisID: "y5",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Rhythm deviation"),
            data: this.rhythmDeviation,
            borderColor: this.getMultichartColor("y6"),
            backgroundColor: this.getMultichartColor("y6"),
            yAxisID: "y6",
            display: false,
          },
          {
            elements: {
              point: {
                radius: 0,
              },
              line: {
                tension: 0.4,
              },
            },
            label: this.$t("tracking.Heart rate"),
            data: this.heartRate,
            borderColor: this.getMultichartColor("y7"),
            backgroundColor: this.getMultichartColor("y7"),
            yAxisID: "y7",
            display: false,
          },
        ],
      };

      const selectedChartsIdArray = this.multicharts
        .filter((chart) => chart.checked)
        .map((i) => i.scaleId);

      initialChartData.datasets = initialChartData.datasets.filter((i) =>
        selectedChartsIdArray.includes(i.yAxisID)
      );

      // Sync datasets by X axes
      initialChartData.datasets = syncDatasetsByX(
        initialChartData.labels,
        initialChartData.datasets
      );

      this.originalFullData = initialChartData;
      this.multichartData = this.getDecimatedChartData(initialChartData);

      // Generate decimated secondsByGait
      this.decimatedSecondsByGait = this.generateGeneralizedGaits();
    },

    generateSpeedByGPS() {
      if (this.reports.GPS?.resampled) {
        return this.trainingTime.map((i, idx) => {
          const index = this.cutValue ? this.cutValue[0] + idx : idx;
          return {
            x: i,
            y: this.reports.GPS.resampled.speed[index]?.toFixed(2) ?? null,
          };
        });
      }
      return [];
    },

    generateSpeedByML() {
      if (this.reports.SUMMARY_RESAMPLED) {
        return this.trainingTime.map((i, idx) => {
          const index = this.cutValue ? this.cutValue[0] + idx : idx;
          return {
            x: i,
            y:
              this.reports.SUMMARY_RESAMPLED.avg_vel[index]?.toFixed(2) ?? null,
          };
        });
      }
      return [];
    },

    generateHeartRate() {
      return this.HRDatafiles.map((i, idx) => {
        return {
          x: formatTimestampToSingleDecimal(i.ts),
          y: i.hr[idx],
        };
      });
    },
  },
  async mounted() {
    this.prepareInitialChartData();

    if (this.localZoom.x.max === 0) {
      this.SET_MULTICHART_LOCAL_ZOOM({
        x: {
          min: 0,
          max: this.trainingTime.length,
        },
      });
    } else {
      await this.$nextTick();
      this.SET_MULTICHART_LOCAL_ZOOM({
        ...this.localZoom,
        x: {
          min: this.localZoom.x.min + 1,
          max: this.localZoom.x.max,
        },
      });
      this.visibleChartWasUpdatedFlag = true;
    }
    this.setNewLegendData(this.currentTrackIndex);
  },
};
</script>
