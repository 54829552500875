<template>
  <Disclosure as="nav" class="w-full fixed z-50">
    <div
      class="w-full flex flex-wrap justify-between bg-white sm:px-4 relative l-0 r-0 z-50 shadow-md"
    >
      <div class="flex h-16 md:mx-20 justify-start">
        <div class="flex px-2 lg:px-0">
          <div class="flex items-center">
            <svg
              v-if="isShowSidebar"
              @click="$emit('emitSwitchSidebar')"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              class="mr-4 h-[40px] w-[40px] text-[5A5A5F] cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <div v-else class="w-[40px] mr-4"></div>
            <Button @click="$router.push({ name: routeNames.home })">
              <img
                class="block h-[60px] w-auto my-2"
                :src="require('@/assets/img.png')"
                alt="Company logo"
              />
            </Button>
          </div>
        </div>
      </div>
      <ul
        role="list"
        class="mx-auto lg:mx-4 mb-4 md:mb-0 flex flex-wrap justify-center items-center"
      >
        <NotificationsComponent />

        <li
          v-for="item in userNavigation"
          :key="item.name"
          class="font-poppins text-[#5A5A5F]"
        >
          <button
            @click.prevent="handleClick(item)"
            class="flex justify-start items-center h-[44px] w-full px-2"
            :class="{ 'text-gray-500, cursor-default': item.disabled }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-[24px] h-[24px] mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                :d="item.img"
              />
            </svg>
            <span class="text-[18px]">{{ $t(item.title) }}</span>
          </button>
        </li>
        <div class="ml-4 mr-2">
          <LangSwitcherComponent />
        </div>
      </ul>
    </div>
  </Disclosure>
  <ModalComponent
    small
    :is-open="logoutModal"
    @handleCancel="handleCancel"
    @handleAccept="handleAccept"
  >
    <template v-slot:title>
      <span class="px-2">{{ $t("Confirm logout") }}</span>
    </template>
    <template v-slot:content>
      <div class="text-[#5A5A5F] font-semibold px-2">
        {{ $t("Are you sure you want to logout from") }}
        <span class="font-bold">{{ $t("Logistic Dashboard") }}</span
        >?
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import { Disclosure } from "@headlessui/vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { mapActions } from "vuex";
import LangSwitcherComponent from "@/components/blocks/header/LangSwitcherComponent.vue";
import NotificationsComponent from "@/components/blocks/header/NotificationsComponent.vue";
import routeNames from "@/router/routes";

export default {
  name: "HeaderComponent",
  components: {
    NotificationsComponent,
    Disclosure,
    ModalComponent,
    LangSwitcherComponent,
  },
  data() {
    return {
      routeNames,
      logoutModal: false,
      userNavigation: [
        {
          title: "Shopping",
          href: "https://www.horsecare.si/shop/",
          disabled: false,
          img: "M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z",
        },
        {
          title: "Horses",
          name: "horses",
          disabled: false,
          img: "M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10",
        },
        {
          title: "Taskboard",
          name: "taskboard",
          disabled: false,
          img: "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5",
        },
        {
          title: "Logout",
          action: true,
          img: "M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9",
        },
      ],
    };
  },
  computed: {
    isShowSidebar() {
      return (
        this.$route.name === routeNames.training ||
        this.$route.name === routeNames.centerOfGravityTracking ||
        this.$route.name === routeNames.tracking ||
        this.$route.name === routeNames.comparison
      );
    },
  },
  methods: {
    ...mapActions(["logout", "closeAllFullscreenWindows"]),
    handleClick(item) {
      if (item.disabled) return;
      if (item.name) {
        item.name !== routeNames.horses
          ? this.$router.push({ name: item.name })
          : this.$router.push({ name: item.name, params: { page: 0 } });
      } else if (item.action) {
        this.logoutModal = true;
        this.closeAllFullscreenWindows();
      } else if (item.href) {
        window.open(item.href, "_blank");
      }
    },
    handleCancel() {
      this.logoutModal = false;
    },
    handleAccept() {
      this.logoutModal = false;
      this.logout();
    },
  },
};
</script>
